export enum Features {
  CATCH_UP_FRIDAYS = 'catch-up-fridays',
  SUMMATIVE_TEST = 'summative-test',
  ACTIVITIES = 'activity',
  QUIZMAKER = 'short-quiz',
  LESSON_PLAN = 'lesson-plan',
  UI_REVAMP = 'ui-revamp',
  CEREBRO_TEACHING_GUIDE = 'cerebro-teaching-guide',
  DLL = 'dll',
  OBJECTIVES = 'objectives',
  ANNOUNCEMENT_BANNER = 'announcement-banner',
  ANALOGY = 'analogy',
  SUBJECT_EXTENSIONS = 'cross-subject-extension',
  IB_NAVBAR = 'ib-navbar',
}
