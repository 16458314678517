/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import * as CreditsApi from './Credits/Credit';

export type UserCreditType = {
  credits: number;
  datetimeCreated: string;
  datetimeUpdated: string;
  email: string;
  id: string;
  lastFreeTopup: string;
  paidCredits: number;
  userId: string;
};

export const getUserCredit = (): UseQueryResult<UserCreditType> => {
  return useQuery({
    queryKey: ['userCredit'],
    queryFn: () => CreditsApi.getUserCredit(),
    enabled: false,
  });
};
