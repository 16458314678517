import { createContext } from 'react';

import { CreditDispatchEvent } from './enum';
import { InitialStateType } from './provider';
import { UserCreditType } from '../../api/external/Credits';

export type CreditContextType = {
  context: InitialStateType;
  dispatch: <T extends UserCreditType>(actionType: CreditDispatchEvent, payload?: T | undefined) => void;
};

const CreditContext = createContext({} as CreditContextType);

export default CreditContext;
