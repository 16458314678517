import { ReactNode, useEffect, useState } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

import MainLayout from '../components/MainLayout';
import PopUpModal from '../components/PopUpModal';
import { CreditContextProvider } from '../context/credits/provider';
import { PortalProvider } from '../context/portal/provider';
import { SurveyProvider } from '../context/survey/provider';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactNode) => ReactNode;
};

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    person_profiles: 'identified_only',
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
    autocapture: false, // Disable automatic event capture, as we capture manually
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).posthog = posthog;
}

const queryClient = new QueryClient();
export const SITE = {
  title: 'Lesson Planner PH',
  description:
    'Create a lesson plan in no time! Choose the lesson plan format, class details, and learning objectives. Our lesson planning software will generate a lesson plan for you.',
  logo: '/images/logo.png',
  url: 'https://lessonplanner.ph',
  type: 'website',
  keywords: [
    'Lesson planning software',
    'Curriculum mapping tool',
    'Classroom management app',
    'Teacher productivity tool',
    'Educational resource organizer',
    'Digital lesson planner',
    'Teacher scheduling software',
    'Student progress tracker',
    'Assessment and grading tool',
    'Classroom collaboration platform',
    'Lesson plan creator',
    'Instructional materials management',
    'Learning management system',
    'Teacher planner app',
    'Subject-specific lesson templates',
    'Differentiated instruction support',
    'Filipino curriculum alignment',
    'Parent-teacher communication tool',
    'Classroom assessment strategies',
    'Interactive whiteboard integration',
    'Attendance tracking system',
    'Filipino language teaching resources',
    'Classroom behavior management',
    'Professional development resources',
    'Lesson plan sharing community',
    'Assessment data analysis',
    'Student engagement strategies',
    'Multimodal learning activities',
    'Formative and summative assessment',
    'Lesson plan customization options',
    'Lesson plan sharing community',
    'Teacher collaboration platform',
    'Lesson plan maker',
    'Lesson plan template',
    'Lesson plan format',
    'Lesson plan example',
    'Lesson plan sample',
    'Lesson plan outline',
    'Lesson plan for teachers',
    'Lesson plan for elementary',
    'Lesson plan for high school',
    'Lesson plan for kindergarten',
    'Lesson plan for preschool',
    'Lesson plan for grade 1',
    'Lesson plan for grade 2',
    'Lesson plan for grade 3',
    'Lesson plan for grade 4',
    'Lesson plan for grade 5',
    'Lesson plan for grade 6',
    'Lesson plan for grade 7',
    'Lesson plan for grade 8',
    'Lesson planner for teachers',
    'Lesson planner for elementary',
    'Lesson planner for high school',
    'Lesson planner for kindergarten',
    'Lesson planner for preschool',
    'Lesson Plan',
    'Teacher Planner',
    'Lesson Planner',
    'Lesson Planning',
    'Comprehensive Lesson Plan',
    'Detailed Lesson Plan',
    'Semi-Detailed Lesson Plan',
    'Daily Lesson Log',
    'Weekly Lesson Log',
    'Professional Lesson Plan',
    'Master Teacher Lesson Plan',
  ],
};

function CustomApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const getLayout =
    (Component as NextPageWithLayout).getLayout ?? ((page) => page);

  const [isInAppBrowser, setIsInAppBrowser] = useState(false);

  function checkInAppBrowser() {
    if (typeof navigator !== 'undefined') {
      const userAgent = navigator.userAgent || navigator.vendor;

      // List of common in-app browser identifiers
      const inAppBrowserIdentifiers = [
        'FBAN', // Facebook
        'FBAV', // Facebook
        'Instagram', // Instagram
        'Twitter', // Twitter
        'Line', // Line
        'Snapchat', // Snapchat
        'WhatsApp', // WhatsApp
        'Messenger', // Facebook Messenger
        'LinkedIn', // LinkedIn
        'Pinterest', // Pinterest
      ];

      return inAppBrowserIdentifiers.some((identifier) =>
        userAgent.includes(identifier),
      );
    }
    return false;
  }

  useEffect(() => {
    setIsInAppBrowser(checkInAppBrowser());
  }, []);

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  function ComponentWithLayout() {
    return getLayout(<Component {...pageProps} />);
  }

  return (
    <>
      <Head>
        <title>{SITE.title}</title>
        <meta key="desc" content={SITE.description} name="description" />
        <meta content={SITE.title} property="og:title" />
        <meta
          key="og:desc"
          content={SITE.description}
          property="og:description"
        />
        <meta content={SITE.logo} property="og:image" />
        <meta content={SITE.url} property="og:url" />
        <meta content={SITE.type} property="og:type" />
        <meta content={SITE.keywords.join(', ')} name="keywords" />
      </Head>
      <ToastContainer
        enableMultiContainer
        hideProgressBar
        containerId="universalToastContainer"
      />
      <PopUpModal
        header={'Open Lesson Planner PH in the browser'}
        imagePath={'/images/logo-with-text.svg'}
        isOpen={isInAppBrowser}
        okButtonText={'Got it'}
        body={
          "Google doesn't allow sign in from within a in-app browser. To sign in with  Google, please open the page in your browser app."
        }
        setIsOpen={() => {
          setIsInAppBrowser(checkInAppBrowser());
        }}
      />
      <SessionProvider session={pageProps.session}>
        <PostHogProvider client={posthog}>
          <QueryClientProvider client={queryClient}>
            <GoogleAnalytics trackPageViews />
            <SurveyProvider>
              <CreditContextProvider>
                <PortalProvider>
                  <MainLayout>
                    <ComponentWithLayout />
                  </MainLayout>
                </PortalProvider>
              </CreditContextProvider>
            </SurveyProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </PostHogProvider>
      </SessionProvider>
    </>
  );
}

export default CustomApp;
