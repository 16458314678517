export const FAQs = [
  {
    question:
      "What's the difference between Regular Credits and Premium Credits?",
    answer:
      '<div>Lesson Planner PH awards 10 Regular Credits every month to your account. You can use these credits to generate basic features like ordinary Lesson Plans, Objectives and Short Quizzes. Features you can use with Regular Credits are marked with the sparkle icon <img src="/images/blue-stars.svg" alt="stars" style="display:inline">. </br></br> Premium Credits are credits you purchase in this page. You can use these credits to generate premium features like Summative Tests and enhanced lesson plans with references. If your Regular Credits run out, you can still use Premium Credits to generate. Premium Features you can use with premium credits are marked with the crown icon <img src="/images/premium-icon.svg" alt="premium icon" width="18" height="18" style="display:inline" />.',
  },
  {
    question: 'What happened to my old credits that I paid for last year?',
    answer:
      '<div>Credits that were topped up, using any of our plans above, including via Gcash, after October 23 were transitioned into premium credits, the number with the crown icon <img src="/images/premium-icon.svg" alt="premium icon" width="18" height="18" style="display:inline" />. </br></br> The rest remain to be regular credits, marked with the sparkle icon <img src="/images/blue-stars.svg" alt="stars" style="display:inline"> that can still be used to generate a normal lesson plan, objective, short quiz, etc.',
  },
  {
    question: 'How do I pay using Gcash / Maya (E-wallets)?',
    answer:
      '<img src="images/how-to-pay-e-wallet.png" alt="How to pay via e-wallet">',
  },
  {
    question: 'How do I pay using Debit or Credit Card?',
    answer:
      '<img src="images/how-to-pay-card.png" alt="How to pay via debit or credit card">',
  },
  {
    question: 'What payment options do you accept?',
    answer:
      'We accept Visa & Mastercard (Debit and Credit Cards), and e-Wallets (GCash, GrabPay, Maya)',
  },
  {
    question: "I don't have a credit card/e-wallet. What do I do?",
    answer:
      "Contact us at help@lessonplanner.ph. We'll help you find a suitable payment option.",
  },
  {
    question: 'When will my lesson plan credits be reflected after I pay?',
    answer:
      "Your lesson plan credits should be reflected in a few minutes. Please refresh the page if it doesn't reflect after 5 minutes.",
  },
  {
    question:
      'I encountered an error while in the Paymongo checkout page. What do I do?',
    answer:
      'Please visit the paymongo site and contact their customer support <a href="https://paymongo.help/en/collections/2160911-payments">https://paymongo.help/en/collections/2160911-payments</href>',
  },
  {
    question: 'Will you store my card information?',
    answer:
      'No, we do not store your card information. It is only used to process the payment.',
  },
];
