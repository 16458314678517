import { ReactNode, useEffect, useState } from 'react';

import CreditContext from './context';
import { CreditDispatchEvent } from './enum';
import { UserCreditType, getUserCredit } from '../../api/external/Credits';
import { useSession } from 'next-auth/react';

export type InitialStateType = {
  userCredit: UserCreditType,
  totalCredits: number,
  isUserPremium: boolean,
  canGeneratePremium: boolean,
  canGenerateRegular: boolean
};

type CreditContextManagerParams = {
  children: ReactNode;
};

const initialState: InitialStateType = {
  userCredit: {
    credits: 0,
    paidCredits: 0,
    lastFreeTopup: '',
    email: '',
    id: '',
    userId: '',
    datetimeCreated: '',
    datetimeUpdated: '',
  },
  totalCredits: 0,
  isUserPremium: false,
  canGeneratePremium: false,
  canGenerateRegular: false,
};

const REGULAR_CREDIT_COST = 1;
const PREMIUM_CREDIT_COST = 2;

export const CreditContextProvider = ({ children }: CreditContextManagerParams) => {
  const [context, setContext] = useState<InitialStateType>(initialState);
  const session = useSession();
  const { data: creditData, refetch } = getUserCredit();

  useEffect(() => {
    if (session.status === 'authenticated') {
      refetch();
    }
  }, [session.status]);

  useEffect(() => {
    if (creditData) {
      dispatch(CreditDispatchEvent.SET_USER_CREDITS, creditData);
    } else {
      dispatch(CreditDispatchEvent.RESET);
    }
  }, [creditData]);

  function dispatch<T extends UserCreditType>(actionType: CreditDispatchEvent, payload?: T) {
    switch (actionType) {

      case CreditDispatchEvent.SET_USER_CREDITS:
        {
          const totalCredits = payload?.paidCredits + payload?.credits;
          const isUserPremium = payload?.paidCredits > 0;
          const canGeneratePremium = isUserPremium && totalCredits >= PREMIUM_CREDIT_COST;
          const canGenerateRegular = totalCredits >= REGULAR_CREDIT_COST;

          setContext((prev) => ({
            ...prev,
            userCredit: payload,
            totalCredits,
            isUserPremium,
            canGeneratePremium,
            canGenerateRegular
          }));
        }
        break;
      case CreditDispatchEvent.RESET:
        setContext(initialState);
        break;
    }
  }

  return (
    <CreditContext.Provider value={{ context, dispatch }}>
      {children}
    </CreditContext.Provider>
  );
};
