import { useState, useEffect } from 'react';

import { differenceInCalendarDays, formatISO, parseISO } from 'date-fns';
import { Survey, posthog } from 'posthog-js';

import useLocalStorage from '../../hooks/useLocalStorage';

import SurveyContext from './context';
import { SurveyDispatchEvent } from './enum';
import { InitialStateType, SurveyProviderProps } from './types';

const initialState = {
  activeSurvey: null,
  shouldShowSurvey: false,
  isLoading: false,
  error: null,
};

type SurveyInteractionRecord = Record<string, string>;

export const SurveyProvider = ({ children }: SurveyProviderProps) => {
  const [context, setContext] = useState<InitialStateType>(initialState);

  const initialSurveyInteractionRecord: SurveyInteractionRecord = {};
  const [hasInteractedWithSurvey, setHasInteractedWithSurvey] = useLocalStorage(
    'hasInteractedWithSurvey',
    initialSurveyInteractionRecord,
  );

  useEffect(() => {
    const fetchActiveSurveys = async () => {
      setContext((prev) => ({ ...prev, isLoading: true, error: null }));

      try {
        const surveys: Survey[] = await new Promise((resolve) => {
          posthog.getActiveMatchingSurveys((surveys) => {
            resolve(surveys);
          }, true);
        });

        for (const survey of surveys) {
          const seenSurveyWaitPeriodInDays =
            survey.conditions.seenSurveyWaitPeriodInDays;
          const lastSeenDateStr = hasInteractedWithSurvey[survey?.id];
          let isWithinPeriod = false;

          if (lastSeenDateStr) {
            const lastSeenDate = parseISO(lastSeenDateStr);
            const currentDate = new Date();
            const daysDifference = differenceInCalendarDays(
              currentDate,
              lastSeenDate,
            );
            isWithinPeriod = daysDifference <= seenSurveyWaitPeriodInDays;
          }

          if (!lastSeenDateStr || !isWithinPeriod) {
            setContext((prev) => ({
              ...prev,
              activeSurvey: survey,
              isLoading: false,
            }));
            break;
          }
        }

        if (!context.activeSurvey) {
          setContext((prev) => ({ ...prev, isLoading: false }));
        }
      } catch (err) {
        setContext((prev) => ({
          ...prev,
          error: err.message,
          isLoading: false,
        }));
      }
    };

    fetchActiveSurveys();
  }, []);

  function dispatch<T>(actionType: SurveyDispatchEvent, payload?: T) {
    switch (actionType) {
      case SurveyDispatchEvent.SUBMIT_SURVEY:
        if (context.activeSurvey) {
          setContext((prev) => ({ ...prev, isLoading: true, error: null }));
          try {
            posthog.capture('survey sent', {
              $survey_id: context.activeSurvey.id,
              $survey_response: payload,
            });
            console.log('Survey submitted successfully!');
            setContext((prev) => ({
              ...prev,
              shouldShowSurvey: false,
              isLoading: false,
            }));
          } catch (err) {
            setContext((prev) => ({
              ...prev,
              error: err.message,
              isLoading: false,
            }));
          }
        }
        break;
      case SurveyDispatchEvent.SHOW_SURVEY:
        if (context.activeSurvey) {
          setContext((prev) => ({ ...prev, shouldShowSurvey: true }));
          posthog.capture('survey shown', {
            $survey_id: context.activeSurvey.id,
          });

          setHasInteractedWithSurvey({
            ...hasInteractedWithSurvey,
            [context.activeSurvey.id]: formatISO(new Date()),
          });
        }
        break;
      case SurveyDispatchEvent.DISMISS_SURVEY:
        setContext((prev) => ({ ...prev, isLoading: true, error: null }));
        try {
          if (context.activeSurvey) {
            posthog.capture('survey dismissed', {
              $survey_id: context.activeSurvey.id,
            });
            setContext((prev) => ({
              ...prev,
              activeSurvey: null,
              isLoading: false,
            }));
          }
        } catch (err) {
          setContext((prev) => ({
            ...prev,
            error: err.message,
            isLoading: false,
          }));
        }
        break;
      default:
        break;
    }
  }

  return (
    <SurveyContext.Provider value={{ context, dispatch }}>
      {children}
    </SurveyContext.Provider>
  );
};
