import * as jwt from 'jsonwebtoken';
import { getSession } from 'next-auth/react';

export async function request(url: string, config: RequestInit) {
  try {
    const { user = null } = await getSession();

    try {
      const { user = null } = await getSession();
      if (!user) {
        throw new Error('User session not found');
      }
      console.log(
        `%c Creating request for: `,
        'background: #222; color: #bada55',
        user,
      );
    } catch (error) {
      console.warn('No existing session:', error);
    }

    const token = jwt.sign(user, process.env.NX_SECRET);

    const authHeader = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
      'User-Id': user?.email,
    };

    config.headers = authHeader;

    console.log(
      `%c Final config: `,
      'background: #222; color: #bada55',
      config,
    );
  } catch (error) {
    console.error('An error occurred in fetch.js inside request:', error);
  }
  return fetch(url, config);
}
