import { useState } from 'react';

import { Portal } from '../../enums/portal.enum';

import PortalContext from './context';

export const PortalProvider = ({ children }: { children: JSX.Element }) => {
  const [selectedPortal, setSelectedPortal] = useState<Portal>(
    Portal.PHILIPPINES,
  );
  const value = {
    selectedPortal,
    setSelectedPortal,
  };
  return (
    <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
  );
};
