import { getSession } from 'next-auth/react';
import { request } from '../../../utils/fetch';

async function getUserCredit() {
  const { user } = await getSession();
  if (!user) {
    console.log('User not found');
    return;
  }

  const url = new URL('/api/v1/credit', process.env.NX_EXTERNAL_BACKEND_URL);

  const response = await request(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const data = await response.json();
    console.error(data);
    throw new Error('Something went wrong.');
  }

  return await response.json();
}

export { getUserCredit };
