export const REGEX = {
  BOLD_TEXT: /^(?![-0-9])(.+):/,
  ALPHA_NUMERIC: /^[a-zA-Z0-9\s]*$/,
  VALID_TEXT_INPUT: /^[A-Za-z0-9.,;:'"()?\-+_=/^\s[\]%!]+$/,
  INVALID_TEXT_INPUT: /[^A-Za-z0-9.,;:'"()?\-+_=/^\s[\]%!]/g,
  SECTION_TITLE:
    /^(Learning across curriculum|Review Motivation|Sample ideas\/questions to assess the activities|Engage|Explore|Explain|Elaborate|Evaluate|Elicit|Extend|Assignment|Activity|Analysis|Abstraction|Application|Assessment|END OF REFERENCES):/,
  // DETECT_ACTIVITY_SECTION_4AS: /(?=Activity:)(?!.*Analysis:)|(?=Analysis)/,
  // DETECT_ACTIVITY_SECTION_5OR7ES: /(?<=^|[\r\n])Explore:(.*?)(?=Explain:|$)/s,
  // DETECT_ACTIVITY_ITEM_FOR_ELABORATION: /Activity [0-9]: /,
  // IDENTIFY_SECTION_TITLE_4AS: /^Activity:([^\n]*)/m,
  // IDENTIFY_SECTION_TITLE_5OR7ES: /^Explore:([^\n]*)/m,
  // ISOLATE_SECTION_TITLE_4AS: /Activity:\n([\s\S]*)/m,
  // ISOLATE_SECTION_TITLE_5OR7ES: /Explore:\n([\s\S]*)/m,
  // EXEMPT_CONTENT_ACTIVITY: /Activity:([^\n]*)/m,
  // EXPLORE_END_SECTION_TITLE_4AS: /^Analysis:([^\n]*)/m,
  // EXPLORE_END_SECTION_TITLE_5OR7ES: /^Explain:([^\n]*)/m,
  // DETECT_UP_TO_BEFORE_EXPLAIN: /(?<=^)(.*?)(?=Explain:|$)/s,
};
