import { useState, useEffect } from 'react';

import Link from 'next/link';
import posthog from 'posthog-js';

import { Features } from '@lessonPlanner/constants';

import { EVENTS, trackEvent } from '../utils/tracking';

interface FeatureFlagPayload {
  messageBody: string;
  callToAction: string;
  callToActionURL?: string;
}

interface Props {
  featureFlag: Features;
}

function AnnouncementBanner(props: Props) {
  const { featureFlag } = props;

  const [canAccessFeature, setCanAccessFeature] = useState(false);
  const [payload, setPayload] = useState<FeatureFlagPayload | null>(null);

  const { callToAction, callToActionURL, messageBody } = payload || {};

  function handleCallToActionClick() {
    if (callToActionURL) {
      trackEvent(EVENTS.ANNOUNCEMENT_BANNER_LINK_CLICKED);
    }
  }

  const callToActionButton = callToActionURL && (
    <Link
      className="flex-none rounded-full bg-[#387DFD] px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 lg:text-lg"
      href={callToActionURL}
      rel="noopener noreferrer"
      target="_blank"
      onClick={handleCallToActionClick}
    >
      {callToAction} <span aria-hidden="true">&rarr;</span>
    </Link>
  );

  useEffect(() => {
    const isEnabled = posthog.isFeatureEnabled(featureFlag);
    setCanAccessFeature(isEnabled);

    if (isEnabled) {
      const flagPayload = posthog.getFeatureFlagPayload(
        featureFlag,
      ) as unknown as FeatureFlagPayload;
      setPayload(flagPayload);
    }
  }, [featureFlag]);

  if (!canAccessFeature || !payload) {
    return null;
  }

  return (
    <div className="sticky top-0 z-50 flex w-full justify-center bg-gradient-to-r from-yellow-400 to-yellow-300 px-4 py-2 sm:px-6 sm:py-2.5">
      <div className="flex flex-col items-center gap-y-2 text-center sm:flex-row sm:gap-x-6">
        <p className="text-sm leading-4 text-blue-900 lg:text-lg">
          {messageBody}
        </p>
        {callToActionButton}
      </div>
    </div>
  );
}

export default AnnouncementBanner;
