export * from './lib/regex';
export * from './lib/credit';
export * from './lib/objective';
export * from './lib/lessonFormat';
export * from './lib/faq';
export * from './lib/quizmaker';
export * from './lib/difficulty';
export * from './lib/materials';
export * from './lib/time';
export * from './lib/activities';
export * from './lib/subjectExtension';
export * from './lib/analogy';
export * from './lib/dailyLessonLog';
export * from './lib/catchUpFridays';
export * from './lib/summativeTest';
export * from './lib/cerebroLessonPlan';
export * from './lib/cerebroFormat';
export * from './lib/features';
export * from './lib/withRetry';
export * from './lib/prompts';
export * from './lib/dllFormat';

export enum GPT_PROVIDER {
  SYMPH = 'SYMPH',
  OPENAI = 'OPENAI',
}

export enum FeatureType {
  LESSON_PLANNER = 'LESSON_PLANNER',
  CATCH_UP_FRIDAYS = 'CATCH_UP_FRIDAYS',
  TEACHING_GUIDE = 'TEACHING_GUIDE',
}

export const CACHEBLE_FEATURES = [
  FeatureType.LESSON_PLANNER,
  FeatureType.CATCH_UP_FRIDAYS,
];
