import { createContext } from 'react';

import { Portal } from '../../enums/portal.enum';

export interface PortalContextType {
  selectedPortal: Portal;
  setSelectedPortal: (portal: Portal) => void;
}

const PortalContext = createContext<PortalContextType | null>(null);
export default PortalContext;
