import { useEffect, useState } from 'react';

import { useSession } from 'next-auth/react';
import { event as analyticsEvent } from 'nextjs-google-analytics';
import posthog from 'posthog-js';
import { usePostHog } from 'posthog-js/react';

export enum EVENTS {
  // Generate Events
  GENERATE_MORE_ACTIVITIES = 'Generate More Activities',
  GENERATE_LP = 'Generate Regular Lesson Plan',
  GENERATE_PREMIUM_LP = 'Generate Lesson Plan With Links And References',
  GENERATE_OBJECTIVE = 'Generate Objective',
  GENERATE_QUIZ_MAKER = 'Generate Quiz Maker',
  GENERATE_ACTIVITY = 'Generate Activity',
  GENERATE_ANALOGY = 'Generate Analogy',
  GENERATE_SUBJECT_EXTENSIONS = 'Generate Learning Within and Across the Curriculum',
  GENERATE_DAILY_LESSON_LOG = 'Generate Daily Lesson Log',
  GENERATE_CATCH_UP_FRIDAYS = 'Generate Catch Up Fridays',
  GENERATE_SUMMATIVE_TEST = 'Generate Summative Test',
  GENERATE_CEREBRO_LESSON_PLAN = 'Generate Cerebro Lesson Plan',

  // Click Events
  CLICK_TOPUP_LINK = 'Redirect To Topup',
  SOCIAL_MEDIA_LINK_CLICKED = 'Social Media Link Clicked',
  ANNOUNCEMENT_BANNER_LINK_CLICKED = 'Announcement Banner Link Clicked',

  // Plan Events
  PLAN_SELECTED = 'Plan Selected',

  // Payment Events
  SUCCESS_USER_PAYMENT = 'User Payment Successful',

  // FAQ
  FAQ_CLICKED = 'FAQ Clicked',

  // API Call Events
  TAVILY_API_CALL = 'Tavily API Call',
}

export const trackEvent = (event: string, userData?: Record<string, any>) => {
  try {
    posthog.capture(event, {
      email: userData?.user_id,
      metaData: userData,
    });
    analyticsEvent(event, userData);
  } catch (error) {
    console.error(error);
  }
};

export const useIdentifyUser = () => {
  const { data: session, status } = useSession();
  const posthog = usePostHog();
  const [isIdentified, setIsIdentified] = useState(false);

  useEffect(() => {
    if (status === 'authenticated' && !isIdentified) {
      posthog.identify(session?.user?.email, {
        email: session?.user?.email,
        name: session?.user?.name,
        organization: session?.user?.organizations[0]?.name,
      });
      setIsIdentified(true);
    }
  }, [status, isIdentified, session?.user?.email, session?.user?.name]);

  return isIdentified;
};
