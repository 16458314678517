import { ReactNode } from 'react';

import { Features } from '@lessonPlanner/constants';

import AnnouncementBanner from './AnnouncementBanner';

interface Props {
  children: ReactNode;
}

function MainLayout(props: Props) {
  const { children } = props;

  return (
    <div className="relative">
      <AnnouncementBanner featureFlag={Features.ANNOUNCEMENT_BANNER} />
      {children}
    </div>
  );
}

export default MainLayout;
