import { useCallback, useState } from 'react';

const useLocalStorage = (key: string, defaultValue: unknown) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window !== 'undefined') {
      try {
        const item = window.localStorage.getItem(key);
        if (!item) return defaultValue;

        // Check if the item is a valid JSON string
        if (/^[\[{].*[\]}]$/.test(item)) {
          return JSON.parse(item);
        }

        return item;
      } catch (error) {
        console.error('Error accessing localStorage:', error);
        return defaultValue;
      }
    } else {
      return defaultValue;
    }
  });

  const setLocalStorageStateValue = useCallback(
    (valueOrFunction: unknown) => {
      if (typeof window !== 'undefined') {
        try {
          // Determine the value to store, supporting both direct values and functional updates
          const valueToStore =
            valueOrFunction instanceof Function
              ? valueOrFunction(storedValue)
              : valueOrFunction;

          // Check if the value to store is an object and stringify it; otherwise, store it directly
          const isObject =
            typeof valueToStore === 'object' && valueToStore !== null;
          const valueForStorage = isObject
            ? JSON.stringify(valueToStore)
            : valueToStore;

          setStoredValue(valueToStore);
          window.localStorage.setItem(key, valueForStorage);
        } catch (error) {
          console.error('Error setting localStorage:', error);
        }
      }
    },
    [key, storedValue],
  );

  const removeLocalStorageItem = useCallback(() => {
    if (typeof window !== 'undefined') {
      try {
        window.localStorage.removeItem(key);
        setStoredValue(undefined);
      } catch (error) {
        console.error('Error removing localStorage item:', error);
      }
    }
  }, [key]);

  return [storedValue, setLocalStorageStateValue, removeLocalStorageItem];
};

export default useLocalStorage;

// const [count, setCount, removeCount] = useLocalStorage("count", 0);
